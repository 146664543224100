import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Brands from "../sections/landing1/Brands";
import Categories from "../sections/landing1/Categories";
import Content1 from "../sections/landing1/Content1";
import FeaturedJobs from "../sections/landing1/FeaturedJobs";
import Content2 from "../sections/landing1/Content2";
import { useStaticQuery, graphql } from 'gatsby';

const IndexPage = () => {
  const [flagShow, setFlagShow] = useState(true);
  const [jobs, setJobs] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      allMongodbMydbJobs {
        nodes {
          id
          title
          company
          description
          location
          urlSource
        }
      }
    }
  `);

  useEffect(() => {
    setJobs(data.allMongodbMydbJobs.nodes);
  }, [data]);

  const filterJobs = (searchItem, lang) => {
    setFlagShow(false);
    const filteredJobs = jobs.filter(job =>
      job.title.toLowerCase().includes(searchItem.toLowerCase())
    );
    setJobs(filteredJobs);
  };

  return (
    <PageWrapper headerConfig={{ bgClass: "dynamic-sticky-bg" }}>
      {flagShow && <Hero filterJobs={filterJobs} />}
      <FeaturedJobs jobs={jobs} />
      <Brands />
      <Categories />
      <Content1 />
      <Content2 />
    </PageWrapper>
  );
};

export default IndexPage;