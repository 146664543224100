import React, { useState } from "react";
import { Select } from "../../components/Core";
import imgH from "../../assets/image/l1/png/hero-image-man.png";
import imgP from "../../assets/image/patterns/hero-pattern.png";

const defaultCountries = [
  { value: "sp", label: "English" },
  { value: "bd", label: "Portoguese" },
  { value: "usa", label: "Remote" },
  { value: "uae", label: "Brazil" },
  { value: "pk", label: "Canada" },
];

const Hero = ({ filterJobs }) => {
  const [searchItem, setSearchItem] = useState("");

  const handleSearch = () => {
    filterJobs(searchItem, "title");
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Previne o envio do formulário
    handleSearch();
  };

  return (
    <div className="bg-gradient-1 pt-26 pt-md-32 pt-lg-33 pt-xl-35 position-relative z-index-1 overflow-hidden">
      <div className="pos-abs-tr w-50 z-index-n2">
        <img src={imgP} alt="" className="gr-opacity-1" />
      </div>
      <div className="container">
        <div className="row position-relative align-items-center">
          <div
            className="col-xxl-6 col-xl-7 col-lg-8 col-md-12 pt-lg-13 pb-lg-33 pb-xl-34 pb-md-33 pb-10"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <h1 className="font-size-11 mb-12 pr-md-30 pr-lg-0">
              Find the perfect job that you deserve.
            </h1>

            <div className="">
              <form onSubmit={handleSubmit} className="search-form shadow-6">
                <div className="filter-search-form-1 bg-white rounded-sm shadow-4">
                  <div className="filter-inputs">
                    <div className="form-group position-relative">
                      <input
                        className="form-control focus-reset pl-13"
                        type="text"
                        id="keyword"
                        placeholder="Job title"
                        value={searchItem}
                        onChange={(e) => setSearchItem(e.target.value)}
                      />
                      <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                        <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                      </span>
                    </div>
                    <div className="form-group position-relative">
                      <Select
                        options={defaultCountries}
                        className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                        border={false}
                      />
                      <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                        <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                      </span>
                    </div>
                  </div>
                  <div className="button-block">
                    <button
                      type="button"
                      onClick={handleSearch}
                      className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
              <p className="heading-default-color font-size-3 pt-7">
                <span className="text-smoke">Search keywords e.g.</span>{" "}
                Product Designer
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-4 col-sm-6 col-xs-6 col-8 pos-abs-br z-index-n1 position-static position-md-absolute mx-auto ml-md-auto"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <div className="ml-xxl-23 ml-xl-12 ml-md-7">
              <img src={imgH} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;